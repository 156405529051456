:root {
    --secondary-text: #65676b;
}

.event-item-container {
    padding-bottom: 50px;
    position: relative;
    width: calc(50% - 8px);
    height: 440px;
    cursor: pointer;
    transition: all 0.2s linear;
}

.event-item-container:hover {
    background-color: #f0f2f5;
}

.event-date-time {
    font-size: 0.8rem;
    font-weight: 500;
}

.event-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1rem;
    font-weight: 600;
}

.event-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.9rem;
    color: var(--secondary-text);
    font-weight: 600;
}

.event-members {
    font-size: 0.7rem;
    color: var(--secondary-text);
    font-weight: 400;
}

.button-container {
    position: absolute;
    width: 100%;
    bottom: 8px;
}
