:root {
    --radius-value: 4px;
}

.container .carousel-control-prev {
    z-index: 1 !important;
}

.rounded-full {
    border-radius: var(--radius-value);
}

.rounded-top {
    border-top-left-radius: var(--radius-value);
    border-top-right-radius: var(--radius-value);
}

.rounded-bottom {
    border-bottom-right-radius: var(--radius-value);
    border-bottom-left-radius: var(--radius-value);
}

.no-rounded {
    border-radius: 0px;
}
