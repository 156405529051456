:root {
    --default-background: #f0f2f5;
    --box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.26);
}

.selected-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 8px;
    line-height: 16px;
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
    user-select: none;
}

.selected {
    cursor: not-allowed;
    background-color: var(--default-background);
}

.selected-item:hover {
    background-color: var(--default-background);
}

.list-item {
    display: none;
    position: absolute;
    width: 100%;
    padding: 12px;
    bottom: 0;
    transform: translateY(100%);
    background-color: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 99999;
}

.list-fanpages {
    width: 100%;
    min-height: 100px;
    max-height: 200px;
    overflow: overlay;
    overscroll-behavior: contain;
}

.active {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
}

.info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.item {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.item:hover {
    background-color: var(--default-background);
}

.name-owner {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}

.position-owner {
    font-size: 12px;
}

.cover-img-container {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--default-background);
}

.cover-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dropdown-icon {
    font-size: 20px;
    color: #0f0f0f !important;
}
