:root {
    --box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.26);
    --event-date: #f3425f;
    --primary-text: #050505;
    --secondary-text: #65676b;
}

.calendar-box {
    position: relative;
    top: -40px;
    left: 15px;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    z-index: 2;
}

.calendar-header {
    width: 100%;
    height: 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--event-date);
}

.calendar-body {
    width: 100%;
    background-color: #fff;
    height: calc(100% - 15px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 500;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.event-name,
.event-date-time {
    position: relative;
    top: -30px;
    left: 15px;
    color: var(--event-date);
    font-size: 0.9rem;
    font-weight: 600;
}

.event-name {
    padding-right: 30px;
    color: var(--primary-text);
    font-size: 1.5rem;
}
.event-description {
    font-size: 15px;
    line-height: 32px;
    font-weight: 400;
    white-space: pre-line;
    text-align: justify;
    color: var(--secondary-text);
}

.fanpage-name,
.fanpage-type {
    font-size: 0.7rem;
    color: var(--secondary-text);
    font-weight: 400;
}

.menu-custom-event {
    border-radius: 4px;
    background-color: #fff;
    z-index: 1000;
    box-shadow: var(--box-shadow);
    position: absolute;
    bottom: -2px;
    transform: translate(-45%, 100%);
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.time-allow-scan-container {
    position: sticky;
    top: -24px;
    left: 0;
    gap: 16px;
    padding-bottom: 8px;
    z-index: 10;
    background-color: #ffff;
}

.time-allow-scan-title {
    font-size: 0.9rem;
    font-weight: 700;
    color: #212529;
}
