:root {
    --default-background: #f0f2f5;
    --box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.26);
}

.cover-images-container {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #f0f2f5;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.button-action-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 1;
}

.list-images-popup {
    display: none;
    gap: 8px;
    width: 95%;
    min-height: 100px;
    max-height: 300px;
    position: absolute;
    left: 3%;
    bottom: 12px;
    transform: translateY(50%);
    padding: 12px;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 9999999999;
    padding-bottom: 60px;
}

.active {
    /* active */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    /*  */
}

.item-image {
    width: 100%;
    height: 100px;
    margin-bottom: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.item-image:hover {
    background-color: var(--default-background);
}

.cover-img-container {
    border-radius: 8px;
    width: 150px;
    height: 100%;
    object-fit: cover;
}

.delete-button {
    position: absolute;
    width: 95%;
    background-color: #fff;
    left: 12px;
    bottom: 10px;
}
