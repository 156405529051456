:root {
    --background-default: #f0f2f5;
}

.search-container {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-default);
    border-radius: 50px;
    padding: 5px 10px;
    font-size: 15px;
}

input {
    width: 100%;
    padding: 0.375rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: border-color.15s ease-in-out, box-shadow.15s ease-in-out;
}

input:focus-visible {
    outline: none;
}

input[type='search']::-webkit-search-cancel-button {
    color: #495057;
}
