:root {
    --default-background: #f0f2f5;
    --box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.26);
}

.recommend-container {
    border-radius: 8px;
    cursor: pointer;
    padding: 6px;
    transition: all 0.2s ease-in;
}

.recommend-container:hover {
    background-color: var(--default-background);
}

.event-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
