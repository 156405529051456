.image-grid-1-items,
.image-grid-2-items,
.image-grid-3-items,
.image-grid-4-items,
.image-grid-5-items {
    display: grid;
    gap: 2px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.image-grid-1-items {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 300px);
}

.image-grid-2-items {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 250px);
}

.image-grid-3-items {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 250px 350px;
}

.image-grid-4-items {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 250px);
}

.image-grid-5-items {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 250px);
}

.image-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

/* 1 items child */
.image-item-grid-1-items-child-1 {
    grid-column: span 1;
    grid-row: span 2;
}
/*  */

/* 2 items child */
.image-item-grid-2-items-child-1 {
    grid-column: span 1;
    grid-row: span 2;
}

.image-item-grid-2-items-child-2 {
    grid-column: span 1;
    grid-row: span 2;
}
/*  */

/* 3 items child */

.image-item-grid-3-items-child-1 {
    grid-column: span 1;
    grid-row: span 1;
}

.image-item-grid-3-items-child-2 {
    grid-column: span 1;
    grid-row: span 1;
}

.image-item-grid-3-items-child-3 {
    grid-column: span 2;
    grid-row: span 1;
}

/*  */

/* 4 items child */
.image-item-grid-4-items-child-1 {
    grid-column: span 2;
    grid-row: span 1;
}

.image-item-grid-4-items-child-2 {
    grid-column: span 2;
    grid-row: span 1;
}

.image-item-grid-4-items-child-3 {
    grid-column: span 2;
    grid-row: span 1;
}

.image-item-grid-4-items-child-4 {
    grid-column: span 2;
    grid-row: span 1;
}

/* ================================= */

/* 5 items child */

.image-item-grid-5-items-child-1 {
    grid-column: span 3;
    grid-row: span 1;
}

.image-item-grid-5-items-child-2 {
    grid-column: span 3;
    grid-row: span 1;
}

.image-item-grid-5-items-child-3 {
    grid-column: span 2;
    grid-row: span 1;
}

.image-item-grid-5-items-child-4 {
    grid-column: span 2;
    grid-row: span 1;
}

.image-item-grid-5-items-child-5 {
    grid-column: span 2;
    grid-row: span 1;
}

/* ================================ */

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}
