/* LOADING PAGE */

.bg_load {
  position: fixed;
  z-index: 9999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.wrapper {
  font-size: 25px;
  /* 1em */
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -100px;
  /* Styles */
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1em dashed rgba(138, 189, 195, 0.5);
  box-shadow: inset 0 0 2em rgba(255, 255, 255, 0.3), 0 0 0 0.7em rgba(255, 255, 255, 0.3);
  animation: rota 3.5s linear infinite;
  /* Font styles */
  font-family: Inter, sans-serif;
  color: #444;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 .04em rgba(255, 255, 255, 1);
  line-height: 6em;
}

.wrapper:before, .wrapper:after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: inherit;
  box-shadow: inset 0 0 2em rgba(255, 255, 255, 0.3);
  border: 1em dashed;
}

.wrapper:before {
  border-color: rgba(138, 189, 195, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.wrapper:after {
  border-color: rgba(138, 189, 195, 0.4);
  top: 1em;
  right: 1em;
  bottom: 1em;
  left: 1em;
}

.wrapper .inner {
  width: 150px;
  height: 150px;
  animation: rota 3.5s linear reverse infinite;
}

.wrapper span {
  display: inline-block;
  animation: placeholder 1.5s ease-out infinite;
}

.wrapper span:nth-child(1) {
  animation-name: loading-1;
}

.wrapper span:nth-child(2) {
  animation-name: loading-2;
}

.wrapper span:nth-child(3) {
  animation-name: loading-3;
}

.wrapper span:nth-child(4) {
  animation-name: loading-4;
}

.wrapper span:nth-child(5) {
  animation-name: loading-5;
}

.wrapper span:nth-child(6) {
  animation-name: loading-6;
}

.wrapper span:nth-child(7) {
  animation-name: loading-7;
}

@keyframes rota {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loading-1 {
  14.28% {
    opacity: 0.3;
  }
}

@keyframes loading-2 {
  28.57% {
    opacity: 0.3;
  }
}

@keyframes loading-3 {
  42.86% {
    opacity: 0.3;
  }
}

@keyframes loading-4 {
  57.14% {
    opacity: 0.3;
  }
}

@keyframes loading-5 {
  71.43% {
    opacity: 0.3;
  }
}

@keyframes loading-6 {
  85.71% {
    opacity: 0.3;
  }
}

@keyframes loading-7 {
  100% {
    opacity: 0.3;
  }
}

/* CUSTOMIZE */
.side-nav .icon-avatar,
.navbar-nav .icon-avatar {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  border: 2px solid #eee;
}

.container-panel {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 15px;
}

.container-panel .container-title {
  position: relative;
  float: left;
  padding: 10px 20px;
  background: linear-gradient(60deg, #45484a, #82858a);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
  border-radius: 3px;
  margin-bottom: -20px;
  margin-left: 20px;
  color: white;
  font-weight: bold;
  z-index: 1;
  font-size: 14px;
}

.container-panel .container-title i {
  margin-right: 5px;
}

.container-panel .container-content .container-btn {
  position: relative;
  text-align: right;
  z-index: 100 !important;
  margin-top: 0px;
  margin-bottom: 10px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12); */
  border: 0;
  font-weight: 400;
  position: relative;
  float: left;
  width: 100%;
  /* border: 1px solid #ddd; */
  border-radius: .25rem;
  background-color: white;
  padding: 15px;
}

.container-panel .container-content .container-top {
  margin-bottom: 10px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12); */
  border: 0;
  font-weight: 400;
  position: relative;
  float: left;
  width: 100%;
  /* border: 1px solid #ddd; */
  border-radius: .25rem;
  background-color: white;
  padding: 15px;
  z-index: 100 !important;
}

.container-panel .container-content .container-table {
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12); */
  border: 0;
  font-weight: 400;
  position: relative;
  float: left;
  width: 100%;
  /* border: 1px solid #ddd; */
  border-radius: .25rem;
  background-color: white;
  padding: 15px;
  z-index: 99 !important;
}

.container-panel .container-content .container-btn button:last-child {
  margin-right: 0px;
}

.container-panel-transparent .container-title {
  display: none;
}

.container-panel-transparent .container-content {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.file-upload {
  visibility: hidden;
  height: 0px;
  width: 0px;
  display: none !important;
}

.container-row {
  width: 100%;
  position: relative;
  float: left;
  margin-bottom: 15px;
}

.container-row h5.title {
  font-size: 13px;
  margin: 5px 0px;
  width: 100%;
  float: left;
}

.container-list {
  display: flex;
  flex-wrap: wrap;
}

.container-list .title {
  width: 100%;
  padding: 5px;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 5px;
}

.container-list .item {
  background: white;
  padding: 10px 15px;
  border: 1px solid rgba(131, 131, 131, .2);
  cursor: pointer;
  margin: 5px 0px;
  position: relative;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  border-radius: 4px;
}

.container-list .item .txt-name {
  font-size: 13px;
  font-weight: bold;
  margin: 5px 0px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.container-list .item .txt-time {
  font-size: 12px;
  color: gray;
  margin: 5px 0px;
}

.container-list .item .txt-icon {
  font-size: 13px;
  margin: 5px 0px;
}

.container-list .item .txt-status {
  font-size: 13px;
  font-weight: bold;
  margin: 5px 0px;
}

.container-list .item .button {
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 15px;
  font-size: 15px;
}

.container-list .item .button i {
  margin-left: 5px;
}

.container-list .item:hover {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  transform: translate(0, -2px);
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .15);
  box-shadow: 0 0 6px rgba(0, 0, 0, .15);
}

.container-list .item:hover .txt-name {
  color: #009fff;
}

.container-list-rating {
  height: 100%;
  overflow: auto;
}

.container-list-rating .list-group-item, .container-list-item .list-group-item {
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}

.list-group .title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

.list-group .container-icon {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 0px;
}

.list-option .title {
  float: left;
  width: 100%;
  position: relative;
}

.list-option .title h4 {
  color: black;
  font-weight: bold;
  z-index: 1;
  font-size: 14px;
  margin: 5px 0px;
}

.list-option .list-option-item {
  position: relative;
  display: inline-block;
  border: 1px solid #ddd;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  border-radius: .25rem;
  /* height: 36px; */
  background-color: #F1F2F8;
}

.list-option .list-option-item.disabled {
  opacity: .5;
	cursor: not-allowed !important;
}

.list-option .list-option-item .media-left {
  padding: 8px 15px;
  font-weight: bold;
}

.list-option .list-option-item .media-right {
  padding: 7.5px 15px;
  cursor: pointer;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -ms-transition: all .3s ease-in;
  transition: all .3s ease-in
}

.list-option .list-option-item .media-right:hover {
  background: red;
  color: white;
  border-radius: 3px;
}

.container-tabs {
  padding: 10px 15px;
  background: linear-gradient(60deg, #302e90, #070569);
  color: white;
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-tabs .nav-tabs {
  background: transparent;
  border: none;
}

.container-tabs .nav-tabs li {
  position: relative;
  height: 40px;
}

.container-tabs .nav-tabs li a {
  background: transparent;
  border: none;
  color: white;
  transition: .3s background-color 0s;
  min-width: 100px;
  border-radius: 4px;
  padding: 10px 15px;
}

.container-tabs .nav-tabs li a i {
  font-size: 16px;
  margin: -1px 5px 0 0;
  vertical-align: middle;
}

.container-tabs .nav-tabs li a.active {
  background-color: rgba(255, 255, 255, .2);
  transition: .3s background-color .2s;
}

.container-tabs-content {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 0px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12); */
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
}

.container-tabs-content .container-btn {
  position: relative;
  text-align: right;
  z-index: 1;
  min-height: 40px;
  margin-top: -5px;
}

.container-tabs-content .container-btn button:last-child {
  margin-right: 0px;
}

.input-image {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: 240px;
  width: 360px;
}

.input-image-icon {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: 64px;
  width: 64px;
}

.icon-avatar {
  height: 30px !important;
  width: 30px !important;
}

.container-item-person .icon-avatar {
  margin-left: -7.5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  background-color: white;
}

.container-item-person .icon-last {
  background: green;
  font-size: 14px;
}

.container-item-person .icon-avatar img {
  border-radius: 50%;
  border: 1px solid #eee;
}

.image-icon {
  height: 30px !important;
  width: 30px !important;
}

.image-icon-auto-width {
  width: auto !important;
  min-width: 30px !important;
}

.small-icon {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: 20px;
  width: 20px;
}

.image-logo {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: 120px;
  min-width: 100px;
  width: auto;
}

.image-qa {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: auto;
  width: 360px;
  max-height: 300px;
}

.image-comment {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: 120px;
  width: auto;
}

.image-comment-thumb {
  height: 80px;
  width: auto;
}

.icon-sticker {
  height: 60px;
  width: auto;
}

.icon-sticker-thumb {
  height: 45px;
  width: 55px;
  box-shadow: none !important;
}

.icon-sticker-small-thumb {
  height: 24px;
  width: 24px;
  box-shadow: none !important;
}

.item-category-sticker {
  border: 1px solid #eee;
  border-radius: .25rem;
  background-color: transparent;
}

.item-category-sticker.active {
  background-color: #302E90;
}

.image-group-qa {
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, .16), 0 0px 2px 0 rgba(0, 0, 0, .12);
  height: 160px;
}

.image-survey {
  border-bottom: 1px solid #eee;
  height: 200px;
}

.image-preview {
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, .16), 0 0px 2px 0 rgba(0, 0, 0, .12);
  height: 480px;
}

.image-icon-select {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: 24px;
  width: 24px;
}

.container-icon-center {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  right: 7.5px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  transition: 0.3s all;
}

.container-icon-center .icon-center {
  position: absolute;
  z-index: 1;
  top: calc(50% - 5px);
  left: calc(50% - 10px);
  cursor: pointer;
  color: #eee;
}

.item-image-comment:hover .container-icon-center {
  visibility: visible;
}

.cell-image-logo img {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
  height: 90px;
  width: auto;
}

.row-title {
  margin-bottom: -12px;
  padding: .3rem;
  margin-top: 5px;
  color: #757575;
}

.txt-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 40px;
}

.rating-block {
  padding: 10px 15px;
  background-color: #FAFAFA;
  border: 1px solid #EFEFEF;
}

.container-text-language {
  padding-left: 15px;
  margin: 0;
}

.container-text-language li {
  float: left;
  width: 100%;
}

.container-text-language .txt-code {
  color: #009fff;
  font-weight: bold;
  margin: 0px;
  float: left;
  width: 30px;
}

.container-text-language .txt-text {
  font-size: 13px;
  margin: 0;
  float: left;
  font-family: Inter;
  width: calc(100% - 30px);
  overflow: hidden;
}

pre {
  font-size: 13px;
  margin: 0;
  float: left;
  font-family: Inter;
  width: calc(100% - 30px);
  overflow: hidden;
}

.w-30 {
  width: 25%!important
}

.container-list-tree .item-list-tree {
  list-style: none;
  padding: 5px 10px;
}

.container-list-tree .item-list-tree[data-level="2"] span.text-title,
.container-list-tree .item-list-tree[data-level="2"] .media {
  padding-left: 20px;
}

.container-list-tree .item-list-tree[data-level="3"] span.text-title,
.container-list-tree .item-list-tree[data-level="3"] .media {
  padding-left: 35px;
}

.container-list-tree .item-list-tree[data-level="4"] span.text-title,
.container-list-tree .item-list-tree[data-level="4"] .media {
  padding-left: 50px;
}

.container-list-tree .item-list-tree[data-level="5"] span.text-title,
.container-list-tree .item-list-tree[data-level="5"] .media {
  padding-left: 65px;
}

.container-list-tree .item-list-tree .media-right {
  visibility: visible;
}

.container-list-tree .item-list-tree:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
  outline: 0;
  cursor: pointer;
}

.container-list-tree .item-not-hover:hover {
  box-shadow: none;
  outline: 0;
  cursor: default;
}

.only-view .item-list-tree:hover {
  box-shadow: none;
  outline: 0;
  cursor: default;
}

.container-list-tree .item-list-tree:hover .media-right {
  visibility: visible;
}

.container-list-tree .item-group-question {
  background-color: #eee;
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.container-list-tree .item-group-question:last-child {
  margin-bottom: 0;
}

.stepper-horizontal {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  border: 0;
  font-weight: 400;
  margin: 15px auto 5px auto !important;
}

.stepper-horizontal li a .label {
  position: absolute;
  width: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  margin-top: 0;
  padding: 0px 5px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.stepper-horizontal li:first-child a .label {
  text-align: left;
}

.stepper-horizontal li:last-child a .label {
  text-align: right;
}

.step-content .step-detail {
  display: none;
}

.step-content .step-active {
  display: block;
}

.input-group-custom .input-group-text {
  padding: 0 5px;
  font-size: inherit;
  color: gray;
}

.input-group-custom .input-group-prepend {
  align-items: center!important;
  display: flex!important;
}

.input-group-custom .form-check-label {
  font-size: inherit;
  top: -5px !important;
}

.input-group-custom .react-datepicker-wrapper input {
  width: calc(100% - 1.5rem);
}

.list-scroll {
  height: 100%;
  overflow: auto;
}

.carousel-item .card-body {
  border: 1px solid #ddd;
  border-top: none;
}

.container-grid .card-body::-webkit-scrollbar {
  width: 0.5em;
}

.container-grid .card-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container-grid .card-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.container-post .item-post {
  background-color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  border-radius: 8px;
  position: relative;
  z-index: 0;
}

.container-form {
  background-color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px;
  border-radius: 8px;
  position: relative;
  z-index: 0;
}

.item-comment .container-content {
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  flex-grow: 1;
  background-color: rgb(244, 246, 248);
}

.float-button {
  position: fixed;
  z-index: 1;
  right: 30px;
  bottom: 30px;
}

.text-reply {
  color: #33b5e5;
  cursor: pointer;
  transition: all 1s;
}

.text-reply:hover {
  text-decoration: underline;
  color: #009fff;
}

.container-reply {
  background-color: rgb(244, 246, 248);
  border-radius: 4px;
}

.container-reply .image-comment {
  height: 60px;
}

.container-reply .icon-sticker {
  height: 45px;
  box-shadow: none !important;
}

.container-reply .icon-avatar {
  height: 30px !important;
  width: 30px !important;
}

.container-list-reply {
  padding-left: 60px;
}

.container-image-blur {
  display: flex;
  position: relative;
  justify-content: center;
  top: 0;
  height: 300px;
  border-radius: .25rem;
}

.container-image-blur::before {
  top: 0px;
  z-index: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(3px);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: rgba(54, 59, 58, 0.72);
  border-radius: .25rem;
}

.container-image-blur img {
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: .25rem;
}

.container-icon-blur {
  z-index: 10;
  margin-top: -40px;
}

.container-icon-blur img {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  box-shadow: 0 5px 11px 0 rgb(255 255 255 / 18%), 0 4px 15px 0 rgb(255 255 255 / 15%);
  margin-right: 10px;
  border: 2px solid #eee;
  background-color: white;
}

.progress-bar.active {
  border: 3px solid #6c37e2;
}

.container-preview-group-self-assessment {
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
}

.container-preview-group-self-assessment .text-title {
  display: inline-block;
  margin-top: -27px;
  position: absolute;
  background: white;
  padding: 0px 5px;
}

.container-preview-group-self-assessment .item {
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}

.container-preview-group-self-assessment .item:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.container-list-file {
  max-height: 250px;
  overflow: auto;
}

.container-list-file .item {
  border-bottom: 1px solid #ddd;
}

.container-list-file .item:last-child {
  border-bottom: none;
}

.container-upload {
  z-index: 1051;
  bottom: 5px;
  right: 5px;
  height: 80px;
  width: 500px;
  position: fixed;
  background: white;
  padding: 15px;
  border: 1px solid #ddd;
}

.card-header-custom {
  background-color: #484a4c !important;
  color: white;
}

.container-layout {
  padding: 15px 20px;
  background: #f2f2f2;
  border-radius: .25rem;
}

.container-list-question .item {
  padding: 15px;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 1s;
}

.container-list-question .item:hover {
  background-color: #eee;
}

.container-list-question .active {
  background-color: #F1F2F8;
}

.text-link:hover {
  color: #007eff;
  text-decoration: underline;
  cursor: pointer;
}

.container-comment-influencer {
  background-color: #f1f2f8;
  padding: 10px 15px;
  border-radius: 0.25rem;
  margin-bottom: 10px;
}

.text-anchor {
  cursor: pointer;
}

.text-anchor:hover {
  color: #009fff;
  text-decoration: underline;
}

.radio-custom {
  background-color: #F1F2F8;
  border-radius: 0.25rem;
  border: 1px solid #eee;
  cursor: pointer;
  transition: all 0.5s;
  color: #ccc;
}

.radio-custom.radio-disabled {
  opacity: .7;
	cursor: not-allowed !important;
}

.radio-custom.active {
  color: black;
}

.radio-custom.active i {
  color: #00c851;
}

.radio-tab-custom {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.radio-tab-custom.active {
  border-bottom: 2px solid #302E90;
}

.btn-menu-toggle {
  cursor: pointer;
  border-radius: 100%;
  /* border: 2px solid #8b8b8b; */
  width: 26px;
  height: 26px;
  text-align: center;
  transition: all .5s;
  background-color: #8b8b8b;
}

.btn-menu-toggle i {
  transition: all .5s;
  color: white !important;
}

.badge-number {
  border-radius: 100%;
  padding: 8px 10px !important;
}

.form-question {
  background-color: #F1F2F8;
  border-radius: .25rem;
  padding: 15px;
  box-shadow: none !important;
}

.form-question .card-footer {
  background-color: transparent;
}

.item-preview-question {
  margin-bottom: 10px;
  background-color: #F1F2F8;
  border-radius: .25rem;
  padding: 15px;
}
.group-question-container {
  margin: 25px 0;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.group-question-container {
  width: 100%;
}
.group-question-recommend {
  display: flex;
  flex-direction: column;
  width: 100%
}

.is_important_box {
  width: 20%
}
.group-question-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
}
.group-question-actions-right {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.answer-score-group {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 10px
}
.answer-score-group .answer-input {
  flex: 1 1 0;
}
.answer-score-group .score-input {
  width: 20%;
}

.cursor-pointer {
  cursor: pointer;
}

.border-radius-left-right-top {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.container-pdf {
  border: 2px solid #eee;
}

/* Schdeule Calendar */
.container-schedule-calendar .view-month .item-title {
  border: 1px solid #eee;
  text-align: center;
  background-color: #302e90;
  color: white;
  font-weight: bold;
}

.container-schedule-calendar .view-month .item {
  height: 130px;
  border: 1px solid #eee;
  text-align: center;
  cursor: pointer;
  width: calc(100%/7);
}

.container-schedule-calendar .view-month .item--1, 
.container-schedule-calendar .view-month .item-1 {
  color: #ccc;
}

.container-schedule-calendar .view-month .item .text-date {
  font-size: 12px;
  font-weight: 600;
}

.container-schedule-calendar .view-month .item-today .text-date {
  color: white;
  background-color: #302e90;
  padding: 4px 5px;
  border-radius: 100%;
}

.container-schedule-calendar .view-week .item {
  width: calc(100%/8);
}

.container-schedule-calendar .view-week .item .container-title {
  border: 1px solid #eee;
  text-align: center;
  font-weight: bold;
  height: 65px;
}

.container-schedule-calendar .view-week .item .container-title .text-date {
  color: grey;
}

.container-schedule-calendar .view-week .item-today .container-title .text-date {
  color: white;
  background-color: #302e90;
  padding: 4px 5px;
  border-radius: 100%;
}

.container-schedule-calendar .view-week .item .container-time {
  border: 1px solid #eee;
  height: 100px;
  cursor: pointer;
}

.container-schedule-calendar .view-list .item-title {
  background-color: #fdfdfd;
  font-weight: 600;
}

.container-schedule-calendar .view-list .item-today .text-title {
  color: #302e90;
}

.container-schedule-calendar .view-list .item-schedule {
  cursor: pointer;
}

.container-schedule-calendar .container-list-schedule .item-schedule {
  font-size: 10px;
  text-align: left;
  background-color: #eee;
  padding: 3px 5px;
  border-radius: 16px;
  margin-bottom: 1px;
  cursor: pointer;
}

.container-schedule-calendar .container-list-schedule .item-schedule .text-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 50px);
}

.container-schedule-calendar .container-list-schedule .item-more {
  font-size: 10px;
  text-align: left;
  cursor: pointer;
  padding: 3px 5px;
  color: #007eff;
  font-weight: bold;
}

.container-schedule-calendar .container-list-schedule .item-more:hover {
  text-decoration: underline;
}

.z-index-100 {
  z-index: 100 !important;
}

.z-index-99 {
  z-index: 99 !important;
}

.text-left {
  text-align: left;
}

.FolderTree .TreeNode .iconContainer i {
  color: #33c;
}

.FolderTree .TreeNode .TreeNodeToolBar {
  margin-left: 10px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
  margin-top: 10px;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
  margin-top: 10px;
}

.textarea-post {
  background-color: transparent !important;
  padding: 30px !important;
  border: none !important;
  height: 200px !important;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
}

.textarea-post:focus {
  background-color: transparent !important;
} 

.container-filter-outside {
  width: 1000px !important;
  right: 0px !important;
  margin-top: 5px !important;
}

.container-map-note {
  position: absolute;
  z-index: 0;
  left: 30px;
  bottom: 30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  background-color: white;
  border-radius: .25rem;
}

.container-info-window {
  overflow: auto;
}

.category-div .md-form.md-outline label.active {
  overflow: hidden;
  height: 1.5rem
}

.create-template:hover{
  background-color: #302e90;
  color: #fff !important;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mode-button{
  background-color: #FFFF;
  border: 1px solid #dadee3;
  padding: 8px 13px;
  /* border-radius: 4px; */
  font-weight: 500;
  transition: all .2s ease-in-out;
}

.mode-button:hover{
  background-color: #e8ecf0a1;
}

.mode-active{
  color: #020914 !important;
  background-color: #e8ecf0ec !important;
}

.chart-title{
  text-align: center;
  margin-top: 1rem;
  font-size: 18px ;
}

.chart-wrapper{
  max-height: 400px !important;
}

.category-active {
  color: #020914 !important;
  background-color: #e8ecf0ec !important;
}

.list-category{
  transition: all 0.2s linear;
}

.list-category:hover{
  background-color: #e8ecf0a1;
}

.input-group-custom{
  width: calc(100% - 45px)
}

.hide-scrollbar::-webkit-scrollbar{
  display: none;
}


.custom-scrollbar::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);
}

.custom-scrollbar::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #00000000;
}

.container-icon-dashboard {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.custom-label label{
  z-index: 0;
}

.survey-post-item {
  position: relative;
  height: 45px;
  border: 1px solid #dee2e6;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  z-index: 2;
  transition: all .2s linear;
}

.survey-post-item:hover {
  border: 1px solid #6d9aad !important;
}

.percentage-bar {
  position: absolute; 
  height: 100%;
  left: 0; 
  top: 0;
  color: #FFFF;
  border-radius: 8px 0 0 8px;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  z-index: -1;
}

.percentage-bar-number {
  position: absolute; 
  font-size: 0.7rem;
  font-weight: 600;
  right: 5px; 
  top: 0; 
  height: 100%;
  z-index: 3;
  text-align: center;
}

.over-flow-hidden {
  overflow: hidden !important;
}

.height-90 {
  height: 90%;
}

.w-33{
  width: 33.33%;
}

/* Gap tổng thể */
.gap-1 { gap: 0.25rem; }   /* 4px */
.gap-2 { gap: 0.5rem; }    /* 8px */
.gap-3 { gap: 0.75rem; }   /* 12px */
.gap-4 { gap: 1rem; }      /* 16px */
.gap-5 { gap: 1.25rem; }   /* 20px */
.gap-6 { gap: 1.5rem; }    /* 24px */
.gap-8 { gap: 2rem; }      /* 32px */
.gap-10 { gap: 2.5rem; }   /* 40px */

/* Gap theo chiều ngang */
.gap-x-1 { column-gap: 0.25rem; }
.gap-x-2 { column-gap: 0.5rem; }
.gap-x-3 { column-gap: 0.75rem; }
.gap-x-4 { column-gap: 1rem; }
.gap-x-5 { column-gap: 1.25rem; }
.gap-x-6 { column-gap: 1.5rem; }
.gap-x-8 { column-gap: 2rem; }
.gap-x-10 { column-gap: 2.5rem; }

/* Gap theo chiều dọc */
.gap-y-1 { row-gap: 0.25rem; }
.gap-y-2 { row-gap: 0.5rem; }
.gap-y-3 { row-gap: 0.75rem; }
.gap-y-4 { row-gap: 1rem; }
.gap-y-5 { row-gap: 1.25rem; }
.gap-y-6 { row-gap: 1.5rem; }
.gap-y-8 { row-gap: 2rem; }
.gap-y-10 { row-gap: 2.5rem; }


@media (max-width: 1200px) {
  .container-filter-outside {
    width: 992px !important;
  }
}

@media (max-width: 992px) {
  .container-filter-outside {
    width: 767px !important;
  }
}

@media (max-width: 767px) {
  .container-filter-outside {
    width: 100% !important;
  }
}