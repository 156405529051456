.error-page {
    height: 100vh;
}

.wrap {
    margin: 0 auto;
    width: 96%;
}

h1 {
    padding: 40px 50px !important;
    color: #603813 !important;
    font-size: 5em !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
}

.banner {
    text-align: center;
    margin-top: 30px;
}

.banner img {
    margin-top: 0px;
}

.page {
    text-align: center;
    font-family: Inter;
}

.page h2 {
    font-size: 3em;
    color: rgb(99, 44, 37);
    font-weight: bold;
}

/*media queries*/

@media all and (max-width:1366px) and (min-width:1280px) {
    .wrap {
        width: 90%;
    }
    .banner {
        margin-top: 30px;
    }
}

@media all and (max-width:1280px) and (min-width:1024px) {
    .wrap {
        width: 90%;
    }
}

@media all and (max-width:1024px) and (min-width:800px) {
    .wrap {
        width: 90%;
    }
    h1 {
        font-size: 2em;
    }
    .banner {
        margin-top: 0px;
    }
    .page h2 {
        font-size: 2em;
    }
}

@media all and (max-width:800px) and (min-width:640px) {
    .wrap {
        width: 90%;
    }
    h1 {
        font-size: 2em;
    }
    .banner {
        margin-top: 10px;
    }
    .banner img {
        width: 34%;
    }
    .page h2 {
        font-size: 2em;
    }
}

@media all and (max-width:640px) and (min-width:480px) {
    .wrap {
        width: 90%;
    }
    h1 {
        font-size: 1.6em;
    }
    .banner {
        margin-top: 0px;
    }
    .banner img {
        width: 32%;
    }
    .page h2 {
        font-size: 1.6em;
    }
}

@media all and (max-width:480px) {
    .wrap {
        width: 90%;
    }
    h1 {
        font-size: 1.4em;
    }
    .banner {
        margin-top: 0px;
    }
    .banner img {
        width: 32%;
    }
    .page h2 {
        font-size: 1.4em;
    }
}

@media all and (max-width:414px) {
    .wrap {
        width: 90%;
    }
    .footer {
        text-align: center;
        line-height: 1.5em;
    }
    h1 {
        font-size: 1.4em;
    }
    .banner {
        margin-top: 10px;
    }
    .banner img {
        width: 80%;
    }
    .page h2 {
        font-size: 1.4em;
    }
}