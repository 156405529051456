:root {
    --outlined-bg-color: #ffff;
    --outlined-bg-hover-color: #e8ecf0a1;
    --standard-bg-color: #385898;
    --standard-bg-hover-color: #4e6ba3;
    --input-bg-color: #0006;
    --input-bg-hover-color: rgba(114, 112, 112, 0.4);
    --light-bg-color: #e4e6eb;
    --light-bg-hover-color: #c7c8ca;
    --primary-text: #1c1e21;
}

.outlined {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    margin: 4px;
    gap: 4px;
    background-color: var(--outlined-bg-color);
    border: 1px solid #dadee3;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.outlined:hover {
    background-color: var(--outlined-bg-hover-color);
}

.menu-item,
.light,
.standard {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    background-color: var(--standard-bg-color);
    color: #ffff;
    border: 1px solid transparent;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.standard:hover {
    background-color: var(--standard-bg-hover-color);
}

.input {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    line-height: 16px;
    font-size: 14px;
    border-radius: 6px;
    background-color: var(--input-bg-color);
    border: 1px solid transparent;
    color: #fff;
}

.input:hover {
    background-color: var(--input-bg-hover-color);
}

.light {
    width: 100%;
    padding: 7px 12px;
    font-size: 0.8rem;
    color: var(--primary-text);
    background-color: var(--light-bg-color);
}

.light:hover {
    background-color: var(--light-bg-hover-color);
}

.menu-item {
    justify-content: start;
    color: var(--primary-text);
    background-color: var(--light-bg-color);
    border-radius: 0;
}
.menu-item:hover {
    background-color: var(--light-bg-hover-color);
}
