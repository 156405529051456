@font-face {
    font-family: Inter;
    src: url(../font/inter/Inter-Thin.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: Inter;
    src: url(../font/inter/Inter-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: Inter;
    src: url(../font/inter/Inter-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Inter;
    src: url(../font/inter/Inter-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: Inter;
    src: url(../font/inter/Inter-Bold.ttf) format('truetype');
    font-weight: 700;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

html {
    scroll-behavior: smooth;
}

.md-form.md-outline label {
    top: -2px;
}

.md-outline .form-control.is-invalid,
.was-validated .md-outline .form-control:invalid {
    border-color: #dc3545;
}

.md-outline .form-control.is-valid,
.was-validated .md-outline .form-control:valid {
    border-color: #28a745;
}

.is-invalid ~ .invalid-feedback {
    display: block;
}

.is-valid ~ .invalid-feedback {
    display: none;
}

/* DROPDOWN */

.dropdown .dropdown-menu {
    background-color: #fff;
    border-radius: 4px;
    padding: 15px;
    min-width: 250px;
    visibility: hidden;
    display: block;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transform: scale(0);
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.dropdown.show .dropdown-menu {
    transform: scale(1);
    visibility: visible;
}

.card .view.gradient-card-header {
    padding: 1rem 1rem;
    text-align: center;
}

.card h3,
.card.card-cascade h4 {
    margin-bottom: 0px;
}

.cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px;
}

.cascading-admin-card .admin-up .fa {
    -webkit-box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .fa {
    padding: 1.5rem;
    font-size: 2rem;
    color: #fff;
    text-align: center;
    margin-right: 1rem;
    border-radius: 3px;
    width: 90px;
}

.cascading-admin-card .admin-up .data {
    float: right;
    margin-top: 2rem;
    text-align: right;
}

@media (max-width: 1199.98px) {
    .card.cascading-admin-card {
        margin-top: 2.5rem;
    }
}

@media (max-width: 576px) {
    .card.cascading-admin-card {
        margin-top: 2.5rem;
    }
}

/* COLLAPSE */

.container-filter .container-filter-content {
    position: absolute;
    z-index: 1000 !important;
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px;
    text-align: left;
    right: 0px;
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.container-filter-sticker .container-filter-content {
    right: inherit !important;
}

.container-filter .show {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    margin-top: 0px;
}

/* TABLE */

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
    display: inline-block;
}

.dataTables_wrapper .dataTables_length {
    margin: 2px 12px 2px 16px;
}

.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
    display: inline-block;
    margin-top: 6px;
    padding: 0px !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_info {
    float: right;
}

.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_paginate {
    float: right;
}

.dataTables_scrollBody {
    margin-bottom: 6px;
}

.dataTables_scroll .dataTables_scrollHeadInner {
    width: 100% !important;
}

table.dataTable {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.table i {
    color: #6a6b7a;
}

.table > thead > tr > th,
.table > thead > tr > td {
    /* background: #33b5e5; */
    text-align: left;
    font-weight: bold;
    outline: none;
    padding: 11px 5px;
    border: none;
    /* border-right: 1px solid #ddd; */
    /* border-bottom: 1px solid #ddd; */
    vertical-align: bottom;
    color: #8a8d9e;
}

.table > tbody > tr.tr-is-existed {
    background: #fff3dc;
}

.table > tbody > tr > td {
    outline: none;
    vertical-align: middle;
}

.table > tbody > tr > td .btn-table {
    padding: 5px 7px;
}

.table > tbody > tr:first-child > td {
    border-top: none;
}

.table > tbody > tr.odd > td {
    outline: none;
}

.table > tbody > tr.even > td {
    outline: none;
}

.table > tbody > tr:hover {
    outline: none;
    background: #eee;
}

.table > tbody > tr > td > i.disabled {
    opacity: 0.3;
}

.table-child > tbody > tr {
    background: #e9f4ff !important;
}

.table-child > tbody > tr:hover {
    outline: none;
    background: #eee !important;
}

.table-view {
    width: 100%;
    height: auto;
    border-collapse: collapse;
}

.table-view td,
.table-view th {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.table-view tr.parent {
    background: #eee;
}

.table-view tr td:nth-child(even) {
    color: #007eff;
}

.table-infowindow tr td {
    vertical-align: top;
}

.container-collapse i.disabled {
    opacity: 0.3;
}

tr[data-level='1'] .container-collapse {
    padding-left: 5px;
}

tr[data-level='2'] .container-collapse {
    padding-left: 20px;
}

tr[data-level='3'] .container-collapse {
    padding-left: 35px;
}

/* BUTTON */

.btn-action {
    min-width: 75px;
}

.btn-self {
    min-width: 200px;
}

.btn-question {
    min-width: 120px;
}

.btn-icon {
    background: transparent;
    border: none;
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

.btn-icon:hover {
    opacity: 1;
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
}

.btn-row {
    box-shadow: none;
    background-color: transparent;
}

.btn-list {
    min-width: 120px;
}

.btn-row:focus,
.btn-row:active {
    outline: none;
}

.btn-row[disabled] {
    color: gray !important;
    opacity: 0.3;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
}

.btn-create:hover {
    color: #498af2;
}

.btn-edit:hover {
    color: orange;
}

.btn-del:hover {
    color: red;
}

.btn-details:hover {
    color: #06c5da;
}

.btn-transparent {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

/* OVER WRITE */

body {
    font-size: 13px;
    font-family: Inter, sans-serif;
}

.double-nav .breadcrumb-dn {
    font-size: 20px;
    color: #8a8d9e;
    font-weight: bold;
}

.double-nav .breadcrumb-dn i {
    font-size: 12px;
    color: #8a8d9e;
}

.double-nav .breadcrumb-dn .active {
    color: black;
}

.side-nav.wide.slim .sn-ad-avatar-wrapper a span {
    display: none;
}

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
}

#root {
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
    background-color: #f0f2f5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.fr-box {
    float: left;
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.fr-toolbar.fr-top {
    border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
}

.second-toolbar {
    border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
}

.height-100 {
    height: 100vh;
}

.min-height-100 {
    min-height: 100vh;
}

.side-nav.wide {
    transition: all 0.5s;
}

.dataTables_wrapper {
    float: left;
    position: relative;
    width: 100%;
}

.container-bg-login {
    background-color: #f1f2f8;
}

.container-bg-login .container-title {
    background-color: #302e90;
    height: 240px;
}

.container-login {
    position: absolute;
    top: 120px;
    left: auto;
}

.container-login .container-login-title {
    background-color: #f1f2f8;
    padding: 15px;
    height: 120px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.form-login {
    padding: 30px;
    border: 1px solid #eee;
    position: relative;
    background-color: white;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    margin-bottom: 100px;
}
.container-header-login {
    z-index: 10;
}
.container-language-login {
    z-index: 1000;
    top: 5px;
    right: 15px;
    position: absolute !important;
}

.container-language-login .dropdown-menu-right {
    right: auto !important;
    margin-top: 10px !important;
}

@media (max-width: 992px) {
    .form-login {
        padding: 30px;
    }

    .container-login {
        padding: 15px;
    }
}

.navbar-footer-login {
    width: 100%;
    border-top: 1px solid #ddd;
    text-align: center;
    bottom: 0;
    position: fixed;
    background-color: #eee;
}

.navbar-footer-login ul {
    margin: 0px;
}

.navbar-footer-login ul li {
    display: inline-block;
    padding: 15px 0px;
}

.navbar-footer-login ul li a {
    color: #909090;
    font-size: 12px;
}

main {
    padding-bottom: 15px;
    min-height: 100vh;
}

footer {
    float: left;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker .react-datepicker__day--outside-month {
    opacity: 0.3;
}

.react-datepicker-wrapper input:focus {
    background: transparent;
}

.input-group .react-datepicker-wrapper {
    position: relative !important;
    flex: 1 1 auto !important;
    width: 1% !important;
}

.input-group .react-datepicker-wrapper .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-transparent .input-group-prepend .input-group-text {
    border-right: none;
    background-color: transparent;
    color: #8457ff;
}

.input-group-transparent .form-control {
    border-left: none;
}

.css-2b097c-container {
    height: auto;
}

.input-group .css-2b097c-container {
    position: relative !important;
    flex: 1 1 auto !important;
    width: 1% !important;
}

.input-group-transparent .css-yk16xz-control,
.input-group-transparent .css-1pahdxg-control {
    border-left: none !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.md-form .css-yk16xz-control,
.md-form .css-1fhf3k1-control {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: 0;
    box-shadow: none;
    box-sizing: content-box;
    background-color: transparent;
    margin: 0 0 0.5rem 0;
    font-size: 0.875rem;
    min-height: 36px;
    z-index: 0;
    margin-bottom: 0;
    background-color: white;
}

.md-form .css-1pahdxg-control {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: transparent;
    box-shadow: inset 0 0 0 1px #33b5e5 !important;
    border: 1px solid #33b5e5 !important;
    margin: 0 0 0.5rem 0;
    font-size: 0.875rem;
    min-height: 38px;
    margin-bottom: 0;
    background-color: white;
}

.css-1uccc91-singleValue {
    font-size: 1rem;
}

.md-form .css-1pahdxg-control .css-1wa3eu0-placeholder {
    transition: color 0.15s ease-in-out;
    color: #4285f4;
}

.md-form .css-1okebmr-indicatorSeparator {
    visibility: hidden;
}

.css-yk16xz-control {
    border-color: #dadee3 !important;
}

.form-control-sm .css-yk16xz-control {
    height: 100%;
    position: initial;
    min-height: 30px !important;
    margin-bottom: 0;
}

.form-control-sm .css-1pahdxg-control {
    height: 100%;
    position: initial;
    min-height: 32px !important;
    margin-bottom: 0;
}

.form-control-sm .css-1hb7zxy-IndicatorsContainer {
    height: 28px;
}

.form-control-sm .css-1wy0on6 {
    height: 28px;
}

.form-control-sm .css-1hwfws3 {
    height: 100%;
    position: initial;
    min-height: 30px;
}

.form-control-sm .css-b8ldur-Input {
    margin: 0px;
}

.input-group-custom .group-select {
    position: relative !important;
    flex: 1 1 auto !important;
    width: 1% !important;
}

.input-group-custom .group-select .css-yk16xz-control,
.input-group-custom .group-select .css-1fhf3k1-control {
    min-height: 32px;
    margin: 0;
}

.input-group-custom .group-select .css-1pahdxg-control {
    min-height: 33px;
    margin: 0;
}

.input-group-custom .group-select .css-1uccc91-singleValue {
    font-size: 13px;
}

.input-group-custom .group-select .css-g1d714-ValueContainer {
    padding-top: 0;
    padding-bottom: 0;
}

.input-group-custom .group-select .css-tlfecz-indicatorContainer,
.input-group-custom .group-select .css-1gtu0rj-indicatorContainer {
    padding: 0;
}

.was-validated .is-invalid .css-yk16xz-control {
    border-color: red !important;
}

.was-validated .is-invalid .css-1pahdxg-control {
    border-color: red !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
}

.was-validated .css-yk16xz-control {
    border-color: #28a745 !important;
}

.was-validated .is-valid .css-1pahdxg-control {
    border-color: #28a745 !important;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
}

.rgba-white-slight,
.rgba-white-slight:after {
    background-color: transparent;
}

.menu-footer {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    font-size: 12px;
}

.menu-footer a {
    height: auto;
    padding: 0px;
    margin-top: 3px;
    font-size: 12px;
    line-height: 1.3;
}

.side-nav .collapsible-account a {
    height: auto;
    line-height: 1;
}

.side-nav ul.collapsible-accordion {
    max-height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: auto;
}

.side-nav ul.collapsible-accordion::-webkit-scrollbar {
    width: 0em;
}

.side-nav ul.collapsible-accordion::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.side-nav ul.collapsible-accordion::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.blur {
    box-shadow: inset 0 0 2px #fefefed1;
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
    background-color: hsla(0, 0%, 100%, 0.8) !important;
}

.shadow-blur {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.mdb-skin .navbar a {
    color: #8b8b8b;
}

@media (max-width: 576px) {
    .mdb-skin .navbar {
        background-color: #243a51 !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .mdb-skin .navbar a {
        color: white !important;
    }
}

.mdb-skin .card-header,
.mdb-skin .form-header {
    background-color: white;
    border-bottom: none;
}

.card {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.mdb-skin .side-nav .sidenav-bg.mask-strong:after,
.mdb-skin .side-nav .sidenav-bg:after {
    background: #302e90;
}

hr.horizontal.light {
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #818181, hsla(0, 0%, 100%, 0));
}

hr.horizontal {
    background-color: transparent;
    border: none;
}

hr:not([size]) {
    height: 1px;
}

.vertical.light {
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.4), transparent);
}

hr.vertical {
    position: absolute;
    background-color: transparent;
    height: 100%;
    right: 0;
    top: 0;
    width: 1px;
    opacity: 0.25;
}

.item-post .MuiCardHeader-subheader {
    font-size: 0.675rem !important;
}

.label-box {
    border-radius: 0.25rem;
    font-size: 75%;
    line-height: 14px;
}

.label-select {
    background-color: #d7e5ff;
    font-weight: bold;
    color: #367cff;
}

.label-light {
    background-color: #eeeeee;
    color: black;
}

.label-warning {
    background-color: #fff6eb;
    color: #fc9905;
}

.label-success {
    background-color: #eaf8f3;
    color: #10c986;
}

.label-danger {
    background-color: #ffebec;
    color: #ff3541;
}

.label-primary {
    background-color: #ebf2ff;
    color: #367cff;
}

.label-info {
    background-color: #e7f8ff;
    color: #33b5e5;
}

.MuiSvgIcon-colorPrimary,
.MuiIconButton-colorPrimary {
    color: #8457ff !important;
}

.MuiListItemAvatar-root {
    min-width: 40px !important;
    margin-top: 0px !important;
}

.MuiListItemText-primary,
.MuiListItemText-secondary {
    font-size: inherit !important;
    color: black !important;
}

.MuiListItemText-primary {
    font-weight: bold !important;
}

.card {
    box-shadow: none !important;
}

.card-box-shadow {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%) !important;
}

.badge-customize {
    background-color: #f3f3f3;
    border-radius: 0.25rem;
    min-width: 40px;
    text-align: center;
}

/* Thanh scroll */
.beautiful-scroll::-webkit-scrollbar {
    width: 12px;
}

/* Thanh chứa scroll */
.beautiful-scroll::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

.beautiful-scroll::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    height: 10px;
}

.beautiful-scroll:hover::-webkit-scrollbar-track {
    background: #c4c4c41c;
}

.beautiful-scroll:hover::-webkit-scrollbar-thumb {
    background: #3c38381c;
}

.gm-style .gm-style-iw-c {
    padding: 16px !important;
}

.gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
}

.gm-style .gm-style-iw-d {
    padding-right: 0 !important;
    min-width: 400px;
    overflow: hidden !important;
}

.icon-color-secondary {
    color: #8457ff;
}

.sum-info-filter .react-datepicker-popper {
    left: -80px !important;
}

@media (max-width: 992px) {
    .sum-info-filter .react-datepicker-popper {
        left: -30px !important;
    }
}
.question-bank-scroll::-webkit-scrollbar {
    width: 12px;
}

.question-bank-scroll::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

.question-bank-scroll::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
    height: 10px;
}

.question-bank-scroll::-webkit-scrollbar-corner {
    background: transparent;
}

.question-bank-scroll:hover::-webkit-scrollbar-track {
    background: transparent;
}

.question-bank-scroll:hover::-webkit-scrollbar-thumb {
    background: grey;
}

.section-div-fanpage {
    display: flex;
    padding: 0.8rem 1rem;
    font-weight: 500;
    font-size: larger;
}

.icon-fanpange {
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

.tab-control-fanpage {
    color: #65676b;
    padding: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.tab-control-fanpage.active {
    color: #0866ff;
    border-bottom: 2px solid #0866ff;
}

.tab-control-fanpage:hover {
    background: #f1f2f8;
}

.tab-control-fanpage.small-content {
    padding: 0.3rem 0.6rem;
    font-size: 0.7rem;
}

.member-fanpage-avatar {
    height: 50px !important;
    width: 50px !important;
    border-radius: 50% !important;
}

.onwer-fanpage-info {
    margin-left: -5px;
    padding: 2px 5px;
    color: #0866ff;
    background-color: #ebf5ff;
}

.action-member-div {
  background-color: white !important;
  border-radius: 5px !important;
  padding: 12px 30px 12px 30px !important;
}

.action-member-div:hover {
    background-color: #f1f2f8 !important;
    border-radius: 5px !important;
}

.fanpage-seting-div {
    width: 90%;
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 400;
    margin: auto;
}

.applied-search {
    background-color: #b4d4ff;
    color: #3559e0;
    padding: 4px;
    border-radius: 10px;
}

.left-block-fanpage {
    width: 30%;
}

.right-block-fanpage {
    width: 70%;
}

.left-div-control {
    width: 23%;
    height: 90%;
    position: fixed;
    overflow: scroll;
}

.content-fanpage {
    width: 80%;
}

.modal-join-fanpage-row {
    width: 80%;
    font-weight: 500;
    font-size: 15px;
}

@media (max-width: 992px) {
    .left-block-fanpage {
        width: 33%;
    }

    .right-block-fanpage {
        width: 67%;
    }
    .left-div-control {
        width: 33%;
    }
    .content-fanpage {
        width: 90%;
    }
}
